import { builder } from '@builder.io/react'
import { publishEvent } from 'lib/events/tracking'
import { Navigation } from 'types/Event/Navigation'

// Using the HOST_NAME just for the pathname and search
// More info on URL(): https://developer.mozilla.org/en-US/docs/Web/API/URL/URL

const HOST_NAME = 'https://www.we-only-need-the-pathname.com'

type setEventProps = {
  eventName: Navigation.LINK_CLICK | Navigation.DROP_DOWN_OPENED | Navigation.DROP_DOWN_BACK_CLICK
  name: string
  url?: string
  type?: string
  placement?: string
}

const setEvents = ({
  eventName,
  name,
  url = '',
  type = 'text',
  placement = 'top',
}: setEventProps) => {
  const newURL = new URL(url, HOST_NAME)
  const permalink = newURL?.pathname
  const searchParams = newURL?.search

  publishEvent(eventName, {
    target_name: name,
    target_permalink: permalink,
    target_filtered_permalink: searchParams || null,
    target_type: type,
    target_placement: placement,
  })
}

export const trackBuilderClicks = content => {
  builder.trackInteraction(content.contentId, (content as any).testVariationId)
}

export default setEvents
