import { useEffect, useState } from 'react'

declare global {
  interface Window {
    matchMedia: (query: string) => MediaQueryList
  }
}

export const useIsLargeTablet = () => {
  const matchMedia = global.window?.matchMedia('(min-width: 768px) and (max-width: 1024px)')
  const [isLargeTablet, setIsLargeTablet] = useState(matchMedia?.matches || false)

  const handleResize = e => {
    setIsLargeTablet(e?.matches || false)
  }

  useEffect(() => {
    if (matchMedia?.addEventListener) matchMedia.addEventListener('change', handleResize)

    return () => {
      if (matchMedia?.removeEventListener) matchMedia.removeEventListener('change', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLargeTablet
}

const useIsTablet = () => {
  const matchMedia = global.window?.matchMedia('(min-width: 768px) and (max-width: 991px)')
  const [isTablet, setIsTablet] = useState(matchMedia?.matches || false)

  const handleResize = e => {
    setIsTablet(e?.matches || false)
  }

  useEffect(() => {
    if (matchMedia?.addEventListener) matchMedia.addEventListener('change', handleResize)

    return () => {
      if (matchMedia?.removeEventListener) matchMedia.removeEventListener('change', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isTablet
}

export default useIsTablet
