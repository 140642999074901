import useSWR from 'swr'
import useSession from 'lib/network/hooks/useSession'
import { get } from 'lib/network/fetchers/everlane'
import ApiError from 'lib/network/ApiError'
import { setCartQty } from 'lib/helpers/wunderkind'
import Cart from 'types/Cart'

export const getCart = async (cartId: number): Promise<Cart> => {
  if (!cartId) return null

  const { cart } = await get<{ cart: Cart }>(`/api/v2/carts/${cartId}`)

  return cart
}

const useCart = ({ addressId = null }: { addressId?: number } = {}) => {
  const { data: session } = useSession()

  const { data, ...result } = useSWR<{ cart: Cart }, ApiError>(
    () => `/api/v2/carts/${session.cart.id}`,
    path => get(path, addressId ? { addressId } : {}),
  )

  // wunderkind
  setCartQty(data?.cart?.lineItems?.length)

  return { ...result, data: data?.cart }
}

export default useCart
