/* eslint-disable no-undef */

const blockName = 'disable-page-scroll'
const blockNameIOS = 'disable-page-scroll_ios'

const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

let scrollX = 0
let scrollY = 0

export function disablePageScroll() {
  if (typeof document !== 'undefined') {
    const { body } = document

    if (body.classList.value.includes(blockName)) {
      return
    }

    body.classList.add(blockName)

    if (isIOS) {
      scrollX = window.scrollX
      scrollY = window.scrollY

      body.classList.add(blockNameIOS)
    }
  }
}

export function enablePageScroll() {
  if (typeof document !== 'undefined') {
    const { body } = document
    body.classList.remove(blockName)

    if (isIOS) {
      body.classList.remove(blockNameIOS)

      window.scrollTo(scrollX, scrollY)
    }
  }
}

export function isPageScrollLocked() {
  if (typeof document !== 'undefined') {
    const { body } = document
    return body.classList.contains(blockName)
  }

  return false
}

// Used to hide scrollbars on the html and body elements. Works well when you only want to
// display a single scrollbar for a full page modal or overlay (first for title sequence)
const HIDE_SCROLLBARS_STYLE_ID = 'hide-scrollbars-style'

export function hideScrollbars() {
  const styleElement = document.createElement('style')
  styleElement.id = HIDE_SCROLLBARS_STYLE_ID
  styleElement.innerHTML = `
    html::-webkit-scrollbar, body::-webkit-scrollbar {
      display: none;
    }
  `
  document.head.appendChild(styleElement)
}

export function showScrollbars() {
  const styleElement = document.getElementById(HIDE_SCROLLBARS_STYLE_ID)
  if (styleElement) {
    document.head.removeChild(styleElement)
  }
}
