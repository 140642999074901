export type ModalState = 'signUp' | 'logIn'
export type CopyType =
  | 'email'
  | 'login'
  | 'signup'
  | 'newUserPromo'
  | 'password'
  | 'passwordResetCompleted'

export const ModalStates = {
  SIGN_UP: 'signUp',
  LOG_IN: 'logIn',
}

export const CopyTypes = {
  EMAIL: 'email',
  LOGIN: 'login',
  SIGN_UP: 'signup',
  NEW_USER_PROMO: 'newUserPromo',
  PASSWORD_RESET: 'password',
  PASSWORD_RESET_COMPLETED: 'passwordResetCompleted',
}
