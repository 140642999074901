import uniq from 'lodash/uniq'
import LineItem from 'types/LineItem'

export function getProductNames(items: LineItem[]) {
  return items.map(item => item.title)
}

export function sentencifyProductNames(productNames: string[] = []): string {
  const uniqueProducts = uniq(productNames)

  if (uniqueProducts.length === 0) return ''
  if (uniqueProducts.length === 1) return uniqueProducts[0]
  if (uniqueProducts.length === 2) return `${uniqueProducts[0]} and ${uniqueProducts[1]}`

  return `${uniqueProducts
    .slice(0, uniqueProducts.length - 1)
    .join(', ')}, and ${uniqueProducts.slice(-1)}`
}

export function getItemCount(lineItems: LineItem[] = []) {
  return lineItems.reduce((total, item) => total + item.quantity, 0)
}
