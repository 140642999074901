import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { uid } from 'react-uid'
import classNames from 'classnames'

import useDebounce from 'lib/helpers/hooks/useDebounce'
import { subscribeToEvent } from 'lib/events/tracking'
import { getPermalinkFromUrl } from 'lib/helpers/navigationV2'

import Event from 'types/Event'
import Menu, { MenuCategory } from 'types/Menu'
import setEvents, { trackBuilderClicks } from '../menuHelper'

import styles from './styles.module.scss'

const CategoryDropdown = dynamic(
  () => import('components/Layout/Navbar/DesktopMenu/CategoryDropdown'),
  { ssr: false },
)

type DesktopMenuProps = {
  menu: Menu
}

const DEFAULT_MENU_EVENT_PROPS = {
  type: 'text',
  placement: 'top',
}

const CategoryLink = ({
  category,
  hoveredCategory,
  setHoveredCategory,
}: {
  category: MenuCategory
  hoveredCategory: MenuCategory
  setHoveredCategory: (MenuCategory) => void
}) => {
  const permalink = getPermalinkFromUrl(category.permalink)
  const showCategoryDropdown =
    category?.sections &&
    category.sections.length > 0 &&
    hoveredCategory?.permalink === category.permalink &&
    hoveredCategory?.text === category.text
  const [isCategoryDropdownRendered, setIsCategoryDropdownRendered] = useState<boolean>(false)

  useEffect(() => {
    if (showCategoryDropdown && !isCategoryDropdownRendered) {
      setIsCategoryDropdownRendered(true)
    }
  }, [showCategoryDropdown, isCategoryDropdownRendered])

  return (
    <li
      className={classNames(styles['desktop-nav__category'], {
        [styles['desktop-nav__category--active']]:
          hoveredCategory?.permalink === category.permalink &&
          hoveredCategory?.text === category.text,
      })}
      onMouseEnter={() => setHoveredCategory(category)}
      onMouseLeave={() => setHoveredCategory(null)}
    >
      <Link
        className={styles['desktop-nav__category-link']}
        style={category.color ? { color: category.color } : {}}
        href={permalink}
        onClick={() => {
          trackBuilderClicks(category)
          setEvents({
            eventName: Event.Navigation.LINK_CLICK,
            name: category.text,
            url: permalink,
          })
        }}
      >
        {category.text}
      </Link>

      {(showCategoryDropdown || isCategoryDropdownRendered) && (
        <CategoryDropdown
          category={category}
          defaultEventProps={DEFAULT_MENU_EVENT_PROPS}
          setHoveredCategory={setHoveredCategory}
          visible={
            hoveredCategory?.permalink === category.permalink &&
            hoveredCategory?.text === category.text
          }
        />
      )}
    </li>
  )
}

const DesktopMenu = ({ menu }: DesktopMenuProps) => {
  const [hoveredCategory, setHoveredCategory] = useState<MenuCategory>(null)

  const debouncedHoveredCategory = useDebounce(hoveredCategory, 150)

  useEffect(() => {
    subscribeToEvent(Event.App.ROUTE, () => {
      setHoveredCategory(null)
    })
  }, [])

  useEffect(() => {
    if (
      debouncedHoveredCategory &&
      (debouncedHoveredCategory.sections?.length > 0 || debouncedHoveredCategory.images?.length > 0)
    ) {
      setEvents({
        ...DEFAULT_MENU_EVENT_PROPS,
        eventName: Event.Navigation.DROP_DOWN_OPENED,
        name: debouncedHoveredCategory.text,
        url: debouncedHoveredCategory.permalink,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedHoveredCategory?.permalink,
    debouncedHoveredCategory?.text,
    debouncedHoveredCategory?.sections?.length,
    debouncedHoveredCategory?.images?.length,
  ])

  if (!menu.categories || menu.categories.length === 0) {
    return null
  }

  return (
    <div className={styles['desktop-nav__container']}>
      <nav className={styles['navbar__wrapper-container']}>
        <ul
          className={classNames(styles['desktop-nav__categories'])}
          onMouseLeave={() => setHoveredCategory(null)}
        >
          {menu.categories
            .filter(category => category.platform !== 'mobile')
            .map((category, i) => (
              <CategoryLink
                key={uid(category?.permalink, i)}
                category={category}
                hoveredCategory={debouncedHoveredCategory}
                setHoveredCategory={setHoveredCategory}
              />
            ))}
        </ul>
      </nav>
      <div
        className={styles['category-dropdown__veil']}
        hidden={
          !hoveredCategory || !hoveredCategory.sections || hoveredCategory.sections?.length < 1
        }
        onMouseEnter={() => setHoveredCategory(null)}
      />
    </div>
  )
}

export default DesktopMenu
