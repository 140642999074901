import classNames from 'classnames'
import { getItemCount } from 'lib/helpers/cart/line_items'
import importEverlaneIcon, { Icon } from 'lib/iconProps'

import useCart from 'lib/network/hooks/useCart'

import styles from './styles.module.scss'

const Cart = importEverlaneIcon(Icon.CART)

const MAX_CART_COUNT = 10

type CartIconProps = {
  height: number | string
  width: number | string
  hideItemCount?: boolean
}

const CartIcon = ({ height, width, hideItemCount = false }: CartIconProps) => {
  const { data: cart } = useCart()
  const itemCount = getItemCount(cart?.lineItems)
  const cartIconCountClassName = classNames({
    [styles['cart-icon__count']]: !hideItemCount,
    [styles['cart-icon__count--large']]: itemCount > MAX_CART_COUNT,
  })

  return (
    <div className={styles.navbar__link}>
      {itemCount > 0 && !hideItemCount && (
        <div className={cartIconCountClassName} aria-label={`${itemCount} items in your cart`}>
          {itemCount > MAX_CART_COUNT ? `${MAX_CART_COUNT}+` : itemCount}
        </div>
      )}
      <Cart width={height} height={width} strokeWidth={2.5} aria-hidden="true" />
    </div>
  )
}

export default CartIcon
