import Session from 'types/Session'

const isAdminUser = (session: Session) => {
  if (!session?.user) return false

  const roles = [...session.user.roles, ...session.user.activeRetailRoles]
  const adminRoles = [
    'cx',
    'creative',
    'logistics',
    'marketing',
    'merch',
    'super_user',
    'retail',
    'international',
  ]
  return !!adminRoles.some(role => roles.includes(role))
}

export default isAdminUser
