import useSWR from 'swr'

import ApiError from 'lib/network/ApiError'
import { WarehouseInventoryCount } from 'types/InventoryCounts'

type UseWarehouseInventoryOptions = {
  skip?: boolean
}

const useWarehouseInventoryCounts = ({ skip = false }: UseWarehouseInventoryOptions = {}) => {
  const { data, ...result } = useSWR<{ inventoryCounts: WarehouseInventoryCount[] }, ApiError>(
    () => (skip ? null : '/api/v2/inventory_counts/warehouse_inventory_counts'),
    { revalidateOnFocus: false },
  )

  return { ...result, data }
}

export default useWarehouseInventoryCounts
