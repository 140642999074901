import { UseMutationOptions } from '../types'
import useMutation from '../useMutation'

type EngagementParams = {
  landingPath: string
  queryString: string
}

const useEngagement = (args: UseMutationOptions<null, EngagementParams>) => {
  return useMutation<null, EngagementParams>('/api/v2/engagements', null, { ...args })
}

export default useEngagement
